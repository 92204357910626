import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeleteRubriquePing } from "../../api/other";
import { useDeleteRubrique, useGetTenantRubriques } from "../../api/rubriques";
import { ReactComponent as DeleteIcon } from "../../assets/CRUD/delete.svg";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import DeleteAlertModal from "../../components/DeleteModal";
import Spinner from "../../components/ui/Spinner";
import { useAuth } from "../../contexts/Auth";
import { RubriqueResponseBody } from "../../types/response/RubriqueResponse";
import { TextMoneyFormat } from "../../utils/TextMoneyFormat";
import { TranslateValueComponet } from "../../utils/TranslateValueComponent";
import { useCaisse } from "./Caisse/Provider";
import RubriqueModal from "./RubriqueModal";

export default function Rubrique(): React.JSX.Element {

    const { t } = useTranslation();
    const { permissions } = useAuth();

    const { tenantId: id, setRubriques, rubriques: rubriquesId, rent, charge, selectedNumberOfMonthsToPay } = useCaisse();
    const { isLoading, data: rubriques } = useGetTenantRubriques(id || "", {
        retry: false,
        onSuccess(data) {
            const arrRubrique = data.filter((rubrique) => !prevRubriques.current.some((prevRubrique) => prevRubrique.id === rubrique.id));

            setRubriques((prev) => {
                return [...prev, ...arrRubrique.map((rubrique) => rubrique.id)];
            });


            prevRubriques.current = [...prevRubriques.current, ...arrRubrique];
        },
    });

    const deleteRubrique = useDeleteRubrique();
    const deleteRubriquePing = useDeleteRubriquePing({
        onSuccess() {
            setRubriques((prev) => {
                return prev.filter((id) => id !== selectedDeleteRubrique?.id);
            });
        }
    });

    const [addRubriques, setAddRubriques] = useState<boolean>(false);
    const [selectedDeleteRubrique, setSelectedDeleteRubrique] = useState<RubriqueResponseBody | null>(null);
    const prevRubriques = useRef<RubriqueResponseBody[]>([]);


    return (
        <article className="bg-white shadow-md p-4 rounded-2xl *:w-full ">
            {isLoading ? (
                <div className="flex items-center justify-center h-full">
                    <Spinner />
                </div>
            ) : (
                <>
                    {permissions?.registerRubrique?.includes("C") &&
                        <PrimaryButton
                            className="!w-fit !mb-4"
                            onClick={() => setAddRubriques(true)}>
                            {t("addRubrique")}
                        </PrimaryButton>
                    }

                    <div className="w-full max-h-96 overflow-y-auto">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-50 *:px-2 *:py-3 *:text-start *:text-sm *:font-semibold">
                                    <th>{t("rubriqueName")}</th>
                                    <th>{t("amount")}</th>
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    className="*:px-2 *:py-3 *:text-start odd:bg-white even:bg-gray-50"
                                >
                                    <td >
                                        {t("loyer")}
                                    </td>
                                    <td>
                                        {TextMoneyFormat(rent * selectedNumberOfMonthsToPay, t)}
                                    </td>
                                    <td />
                                </tr>
                                <tr
                                    className="*:px-2 *:py-3 *:text-start odd:bg-white even:bg-gray-50"
                                >
                                    <td >
                                        {t("charge")}
                                    </td>
                                    <td>
                                        {TextMoneyFormat(charge * selectedNumberOfMonthsToPay, t)}
                                    </td>
                                    <td />
                                </tr>
                                {(rubriques ?? []).length > 0 && rubriques?.filter(rubrique => rubriquesId.includes(rubrique.id))
                                    ?.map((rubrique, i) => {
                                        return (
                                            <tr
                                                key={i}
                                                className="*:px-2 *:py-3 *:text-start odd:bg-white even:bg-gray-50"
                                            >
                                                <td >
                                                    <TranslateValueComponet
                                                        value={rubrique.name}
                                                        valueAr={rubrique.nameAr}
                                                        valueFr={rubrique.nameFr}
                                                    />
                                                </td>
                                                <td>{TextMoneyFormat(rubrique.amount, t)}</td>
                                                <td>
                                                    {permissions?.registerRubrique?.includes("D") && (
                                                        <DeleteIcon
                                                            onClick={() => {
                                                                setSelectedDeleteRubrique(rubrique);
                                                            }}
                                                            className="text-destructive-500 cursor-pointer hover:scale-110 duration-150"
                                                        />
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                        </table>
                    </div>
                </>
            )
            }

            {addRubriques && (
                <RubriqueModal
                    rubriquesId={rubriques?.map(rubrique => rubrique.rubriqueId) || []}
                    onClose={() => setAddRubriques(false)}
                />
            )}

            {selectedDeleteRubrique && (
                <DeleteAlertModal
                    deleteCall={
                        selectedDeleteRubrique.isRegister ?
                            deleteRubrique :
                            deleteRubriquePing
                    }
                    id={selectedDeleteRubrique.rubriqueId}
                    onClose={() => setSelectedDeleteRubrique(null)}
                />
            )}
        </article >
    );
}