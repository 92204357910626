import { useFormik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useCreateBrigadeTransaction, usePostQuote } from "../../../api/payments";
import { useGetUsers } from "../../../api/user";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import InputField from "../../../components/Inputs/InputField";
import InputSelect from "../../../components/Inputs/InputSelect";
import Spinner from "../../../components/ui/Spinner";
import { CAISSETYPE } from "../../../constants";
import { CaisseBrigadeRequestBody } from "../../../types/request/CaisseRequest";
import { formatCreatedAtDate } from "../../../utils/formatDates";
import { TextMoneyFormat } from "../../../utils/TextMoneyFormat";
import ConfirmPopup from "./ConfirmPopup";
import { useCaisse } from "./Provider";
import { createBrigadeValidator } from "./validator";

export default function Brigade(): React.JSX.Element {
  const {
    maxAllowedNumberOfMonthsToPay,
    minAllowedNumberOfMonthsToPay,
    tenantId,
    setTransactionRefrence,
    transactionRefrence,
    numberOfMonthsToPay,
    rubriques,
    setSelectedNumberOfMonthsToPay
  } = useCaisse();

  const { isLoading: isLoadingPayment, mutate: createBrigadeTransaction } = useCreateBrigadeTransaction();
  const { data: cashRegister } = useGetUsers();

  const {
    isLoading: isLoadingQuote,
    data,
    mutate: getQuote,
  } = usePostQuote(tenantId, {
    onSuccess(data) {
      setTransactionRefrence(data.transactionReference);
    },
  });


  const { t } = useTranslation();
  const [confirmPopup, setConfirmPopup] = useState<boolean>(false);

  const isLoading: boolean = isLoadingPayment || isLoadingQuote;

  const initialValues: CaisseBrigadeRequestBody = {
    date: "",
    numero: "",
    months: numberOfMonthsToPay || 1,
    accountId: "",
  };

  const formik = useFormik<CaisseBrigadeRequestBody>({
    initialValues,
    validationSchema: createBrigadeValidator(t, minAllowedNumberOfMonthsToPay, maxAllowedNumberOfMonthsToPay),
    onSubmit: values => {
      if (!transactionRefrence || !confirmPopup) {
        setConfirmPopup(true);
        return;
      }

      createBrigadeTransaction({
        ...values,
        type: CAISSETYPE[5],
        tenantId: tenantId,
        numberOfMonths: values.months,
        transactionReference: transactionRefrence,
        rubriques
      }, {
        onSuccess() {
          toast.success(t("paymentSuccess"));
          setConfirmPopup(false);
          formik.resetForm();
        },
      });
    },
  });

  useEffect(() => {
    if (
      formik.values.months >= minAllowedNumberOfMonthsToPay &&
      formik.values.months <= maxAllowedNumberOfMonthsToPay
    ) {
      getQuote({
        type: CAISSETYPE[5],
        numberOfMonths: formik.values.months,
        rubriques

      });
    }
  }, [formik.values.months, minAllowedNumberOfMonthsToPay, maxAllowedNumberOfMonthsToPay, getQuote, rubriques]);

  useEffect(() => {
    setSelectedNumberOfMonthsToPay(formik.values.months);
  }, [formik.values.months, setSelectedNumberOfMonthsToPay]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="grid grid-cols-2 gap-8 gap-y-6 h-max px-10 pt-2 lg:px-16"
    >
      <InputSelect
        options={cashRegister?.cashiers?.map(user => ({
          label: user.name,
          value: user.id,
        })) || []}
        name="accountId"
        onChange={formik.handleChange}
        value={formik.values.accountId}
        className="col-span-2"
        label="user"
        error={formik.errors.accountId}
        translate={false}
        placeholder="selectUser"
      />

      <InputField
        label="monthsToPay"
        name="months"
        onChange={formik.handleChange}
        placeholder=""
        type="number"
        value={formik.values.months}
        error={formik.errors.months}
      />


      <section className="flex items-center  gap-2">
        <span className="font-medium text-neutral-800">{t("period")} :</span>
        <span className="font-semibold text-primaryGreen">
          {data && (
            <>
              {formatCreatedAtDate(t, data?.fromDate)}
              {formik.values.months > 1 && " - " + formatCreatedAtDate(t, data?.toDate)}
            </>
          )}
        </span>
      </section>

      <InputField
        label="numeroRecu"
        name="numero"
        onChange={formik.handleChange}
        placeholder=""
        type="text"
        value={formik.values.numero}
        error={formik.errors.numero}
      />

      <InputField
        label="paymentDate"
        name="date"
        onChange={formik.handleChange}
        placeholder=""
        type="date"
        value={formik.values.date}
        error={formik.errors.date}
      />

      <section className="col-span-2 flex gap-6  items-center justify-center flex-wrap">
        <div className="flex items-center gap-2">
          <span className="font-medium text-neutral-800">{t("timbre")} (1%) :</span>
          <span className="font-semibold text-primaryGreen">{data && data.timbre >= 0 ? TextMoneyFormat(data.timbre, t) : t("N/A")}</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="font-medium text-neutral-800">{t("tva")} :</span>
          <span className="font-semibold text-primaryGreen">{data && data.tva >= 0 ? TextMoneyFormat(data.tva, t) : t("N/A")}</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="font-medium text-neutral-800">{t("ht")} :</span>
          <span className="font-semibold text-primaryGreen">{data && data.ht >= 0 ? TextMoneyFormat(data.ht, t) : t("N/A")}</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="font-medium text-neutral-800">{t("penaltyAmount")} :</span>
          <span className="font-semibold text-primaryGreen">{data && data.penalty >= 0 ? TextMoneyFormat(data.penalty, t) : t("N/A")}</span>
        </div>
      </section>

      <section className="col-span-2 flex  items-center justify-center gap-4">
        <div className="flex gap-4">
          <span className="text-2xl font-semibold">{t("netAPay")}</span>
          <span className="text-2xl font-semibold text-red-500">
            {isLoadingQuote ? <Spinner /> : TextMoneyFormat(data?.totalQuote || 0, t)}
          </span>
        </div>
        <PrimaryButton disabled={isLoading}>{isLoading ? <Spinner /> : t("saveRecu")}</PrimaryButton>
      </section>

      {confirmPopup && formik.isValid && (
        <ConfirmPopup
          isLoading={isLoading}
          onClose={() => setConfirmPopup(false)}
          totalQuote={data?.totalQuote || 0}
        />
      )}
    </form>
  );
}
