import { useTranslation } from "react-i18next";
import { CaisseVirementResponseBody } from "../../../types/request/CaisseRequest";
import { useFormik } from "formik";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import InputField from "../../../components/Inputs/InputField";
import { createVirementValidator } from "./validator";
import { useCreateVirmentTransaction, usePostQuote } from "../../../api/payments";
import { CAISSETYPE } from "../../../constants";
import Spinner from "../../../components/ui/Spinner";
import { useCaisse } from "./Provider";
import { useEffect, useState } from "react";
import { TextMoneyFormat } from "../../../utils/TextMoneyFormat";
import toast from "react-hot-toast";
import { formatCreatedAtDate } from "../../../utils/formatDates";
import ConfirmPopup from "./ConfirmPopup";

export default function Virement(): React.JSX.Element {
  const {
    maxAllowedNumberOfMonthsToPay,
    minAllowedNumberOfMonthsToPay,
    tenantId,
    setTransactionRefrence,
    transactionRefrence,
    numberOfMonthsToPay,
    rubriques,
    setSelectedNumberOfMonthsToPay
  } = useCaisse();
  const { isLoading: isLoadingPayment, mutate: createVirementTransaction } = useCreateVirmentTransaction();
  const {
    isLoading: isLoadingQuote,
    data,
    mutate: getQuote,
  } = usePostQuote(tenantId, {
    onSuccess(data) {
      setTransactionRefrence(data.transactionReference);
    },
  });

  const isLoading: boolean = isLoadingPayment || isLoadingQuote;
  const [confirmPopup, setConfirmPopup] = useState<boolean>(false);

  const { t } = useTranslation();

  const initialValues: CaisseVirementResponseBody = {
    bank: "",
    virementNumber: "",
    months: numberOfMonthsToPay || 1,
    date: "",
  };

  const formik = useFormik<CaisseVirementResponseBody>({
    initialValues,
    validationSchema: createVirementValidator(t, minAllowedNumberOfMonthsToPay, maxAllowedNumberOfMonthsToPay),
    onSubmit: values => {
      if (!transactionRefrence || !confirmPopup) {
        setConfirmPopup(true);
        return;
      }

      createVirementTransaction({
        ...values,
        type: CAISSETYPE[3],
        tenantId: tenantId,
        numberOfMonths: values.months,
        transactionReference: transactionRefrence,
        rubriques,
      }, {
        onSuccess() {
          toast.success(t("paymentSuccess"));
          setConfirmPopup(false);
          formik.resetForm();
        },
      });
    },
  });

  useEffect(() => {
    if (
      formik.values.months >= minAllowedNumberOfMonthsToPay &&
      formik.values.months <= maxAllowedNumberOfMonthsToPay
    ) {
      getQuote({
        type: CAISSETYPE[3],
        numberOfMonths: formik.values.months,
        rubriques,
      });
    }
  }, [formik.values.months, minAllowedNumberOfMonthsToPay, maxAllowedNumberOfMonthsToPay, getQuote, rubriques]);

  useEffect(() => {
    setSelectedNumberOfMonthsToPay(formik.values.months);
  }, [formik.values.months, setSelectedNumberOfMonthsToPay]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="grid grid-cols-2 gap-8 gap-y-6 h-max px-10 pt-2 lg:px-16"
    >
      <InputField
        label="monthsToPay"
        name="months"
        onChange={formik.handleChange}
        placeholder=""
        type="number"
        value={formik.values.months}
        error={formik.errors.months}
      />

      <section className="flex w-full items-center gap-2">
        <span className="font-medium text-neutral-800">{t("period")} :</span>
        <span className="font-semibold text-primaryGreen">
          {data && (
            <>
              {formatCreatedAtDate(t, data?.fromDate)}
              {formik.values.months > 1 && " - " + formatCreatedAtDate(t, data?.toDate)}
            </>
          )}
        </span>
      </section>


      <InputField
        label="bank"
        name="bank"
        onChange={formik.handleChange}
        placeholder=""
        type="text"
        value={formik.values.bank}
        error={formik.errors.bank}
        className="col-span-2"
      />

      <InputField
        label="virementNumber"
        name="virementNumber"
        onChange={formik.handleChange}
        placeholder=""
        type="text"
        value={formik.values.virementNumber}
        error={formik.errors.virementNumber}
      />


      <InputField
        label="paymentDate"
        name="date"
        onChange={formik.handleChange}
        placeholder=""
        type="date"
        value={formik.values.date}
        error={formik.errors.date}
      />

      <section className="col-span-2 flex gap-6  items-center justify-center flex-wrap">
        <div className="flex items-center gap-2">
          <span className="font-medium text-neutral-800">{t("tva")} :</span>
          <span className="font-semibold text-primaryGreen">{data && data.tva >= 0 ? TextMoneyFormat(data.tva, t) : t("N/A")}</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="font-medium text-neutral-800">{t("ht")} :</span>
          <span className="font-semibold text-primaryGreen">{data && data.ht >= 0 ? TextMoneyFormat(data.ht, t) : t("N/A")}</span>
        </div>
        <div className="flex items-center gap-2">
          <span className="font-medium text-neutral-800">{t("penaltyAmount")} :</span>
          <span className="font-semibold text-primaryGreen">{data && data.penalty >= 0 ? TextMoneyFormat(data.penalty, t) : t("N/A")}</span>
        </div>
      </section>


      <section className="col-span-2 flex  items-center justify-center gap-4">
        <div className="flex gap-4">
          <span className="text-2xl font-semibold">{t("netAPay")}</span>
          <span className="text-2xl font-semibold text-red-500">
            {isLoadingQuote ? <Spinner /> : TextMoneyFormat(data?.totalQuote || 0, t)}
          </span>
        </div>
        <PrimaryButton
          disabled={isLoading}
        >{isLoading ? <Spinner /> : t("saveRecu")}
        </PrimaryButton>
      </section>

      {confirmPopup && formik.isValid && (
        <ConfirmPopup
          isLoading={isLoading}
          onClose={() => setConfirmPopup(false)}
          totalQuote={data?.totalQuote || 0}
        />
      )}
    </form>
  );
}
