import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";
import { useGetInvoices } from "../../api/invoices";
import { useDownloadInvoice } from "../../api/payments";
import { ReactComponent as EmptySVG } from "../../assets/emptySvg.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import Pagination from "../../components/Pagination";
import Spinner from "../../components/ui/Spinner";
import { useAuth } from "../../contexts/Auth";
import { TextMoneyFormat } from "../../utils/TextMoneyFormat";
import { formatCreatedAtDate } from "../../utils/formatDates";
import { TRANSACTION_STATUS_COLOR } from "./utils";

export default function TenantInvoices(): React.JSX.Element {
  const { id } = useParams<{ id: string }>();
  const { selectedWorkspace } = useAuth();

  const { isLoading, data } = useGetInvoices(id || "", selectedWorkspace?.workspaceId || "");
  const { mutate: handleDownload } = useDownloadInvoice();

  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const currentPage = Math.max(Number(searchParams.get("page")) || 1, 1);
  const { pageCount, response: invoices } = data ?? {};

  return (
    <>
      <article className="w-full bg-white p-4">
        {isLoading ? (
          <div className="flex h-full w-full flex-col items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <table className="w-full table-auto">
            <thead>
              <tr className="bg-gray-50 *:px-2 *:py-3 *:text-start *:text-sm *:font-semibold">
                <th>{t("paymentDate")}</th>
                <th>{t("price")}</th>
                <th>{t("from")}</th>
                <th>{t("to")}</th>
                <th>{t("paidMonthsNumber")}</th>
                <th className="!text-center">{t("status")}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {invoices && invoices.length > 0 ? (
                invoices.map(invoice => (
                  <tr
                    key={invoice.id}
                    className={`*:px-2 *:py-3 *:text-start odd:bg-white even:bg-gray-50`}
                  >
                    <td>{invoice.paidAt.replace("T", " ")}</td>
                    <td>{TextMoneyFormat(invoice.amount, t)}</td>
                    <td>{formatCreatedAtDate(t, invoice.fromDate)}</td>
                    <td>{formatCreatedAtDate(t, invoice.toDate)}</td>
                    <td>{invoice.months ? invoice.months + " " + t("months") : "/"}</td>
                    <td>
                      <div
                        className={`flex items-center justify-center rounded-xl p-2 text-sm ${TRANSACTION_STATUS_COLOR[invoice.status as keyof typeof TRANSACTION_STATUS_COLOR]}`}
                      >
                        {invoice.status === "success" && t("success")}
                        {invoice.status === "partiallyPaid" && t("partiallyPaid")}
                        {invoice.status === "pending" && t("pending")}
                        {invoice.status === "failed" && t("notPaid")}
                        {invoice.status === "canceled" && t("canceled")}
                      </div>
                    </td>
                    <td>
                      <button
                        className="group p-1"
                        onClick={() =>
                          invoice.transactionReference &&
                          handleDownload(invoice.transactionReference, {
                            onSuccess(response) {
                              const blob = new Blob([response.data], {
                                type: response.headers["content-type"],
                              });

                              const url = window.URL.createObjectURL(blob);

                              const a = document.createElement("a");
                              a.href = url;
                              a.download = `invoice-${invoice.transactionReference}.pdf`;
                              a.style.display = "none";

                              document.body.appendChild(a);
                              a.click();

                              window.URL.revokeObjectURL(url);
                              document.body.removeChild(a);
                            },
                          })
                        }
                      >
                        <DownloadIcon className="w-5 text-primaryGreen duration-150 group-hover:scale-110" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className="flex h-96 flex-col items-center justify-center rounded-xl bg-white">
                      <EmptySVG />
                      <span className="mt-2 text-lg text-primaryGray">{t("empty_invoice")}</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </article>
      {pageCount ? (
        <Pagination
          currentPage={currentPage}
          totalPages={pageCount}
        />
      ) : null}
    </>
  );
}
